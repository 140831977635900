
import { Options, Vue } from "vue-class-component";
import CallToActionCaseStudy from "@/components/public/case-studies/CallToActionCaseStudy.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import ThothButton from "@/components/common/ThothButton.vue";
import { ButtonSize, ButtonEmphasis } from "@/components/common/ThothButton.vue";
import LeftChevronIcon from "@/assets/images/ChevronLeft.svg";

@Options({
  components: {
    CallToActionCaseStudy,
    ThothButton
  },
})
export default class NeuripsCaseStudy extends Vue {
  images = {
    background: STATIC_ASSET_IMAGES_BASE_URL + "/case-study-cta-background.png",
    speakerPortraits: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-speaker-portraits.jpg",
    openModels: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-open-models.png",
    vision: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-vision.png",
    scalingGpus: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-scaling-gpus.png",
    postTransformers: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-post-transformers.png",
    syndata: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-syndata.png",
    smolModels: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-smol-models.png",
    agents: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-agents.png",
    startupIdeas: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-startup-ideas.png",
    thothLogo: STATIC_ASSET_IMAGES_BASE_URL + "/thoth-blue-logo.png",
    lsLogo: STATIC_ASSET_IMAGES_BASE_URL + "/latent-space-logo.png",
    decibelLogo: STATIC_ASSET_IMAGES_BASE_URL + "/decibel-logo.png",
    databricksLogo: STATIC_ASSET_IMAGES_BASE_URL + "/databricks-logo.png",
    sgnLogo: STATIC_ASSET_IMAGES_BASE_URL + "/sgn-logo.png",
    logo1943: STATIC_ASSET_IMAGES_BASE_URL + "/1943-logo.png",
    cloudflareLogo: STATIC_ASSET_IMAGES_BASE_URL + "/cloudflare-logo.png",
    strongComputeLogo: STATIC_ASSET_IMAGES_BASE_URL + "/strong-compute-logo.png",
    falAILogo: STATIC_ASSET_IMAGES_BASE_URL + "/fal-ai-logo.png",
    daylightLogo: STATIC_ASSET_IMAGES_BASE_URL + "/daylight-logo.png",
  };
  links = {
    calendly: "https://calendly.com/thoth-art-jefferson/learn-about-thoth",
    hfCosmopedia: "https://huggingface.co/blog/cosmopedia",
    hfSmollm: "https://huggingface.co/blog/smollm",
    dropbox: "https://www.dropbox.com/scl/fo/8s6387ue0a5s37pwrayfi/AO5f1SDrc_D1DKHgcBomMUc?rlkey=2nxn3iu9si05s8d5zm9hpsrd3&st=ys57ohaz&dl=0",
    eventPage: "https://lu.ma/LSLIVE?tk=ZO3lLC",
    latentSpace: "https://www.latent.space/",
    thoth: "https://thoth.art"
  };
  sources = {
    conviction: "https://www.conviction.com/startups.html",
    openModels: STATIC_ASSET_IMAGES_BASE_URL + "/open-models.pdf",
    vision: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-vision.pdf",
    postTransformers: STATIC_ASSET_IMAGES_BASE_URL + "/post-transformers.pdf",
    syndata: this.links.hfCosmopedia,
    smolModels: this.links.hfSmollm,
    agents: STATIC_ASSET_IMAGES_BASE_URL + "/agents.pdf",
    scalingGpus: "https://www.dwarkeshpatel.com/p/dylan-jon"
  };
  cosmopediaAuthors = [
    "Loubna Ben Allal",
    "Anton Lozhkov",
    "Daniel van Strien"
  ];
  scalingGpusAuthors = ["Dwarkesh Patel", "Dylan Patel", "Jon Y"];
  smolModelsAuthors = ["Loubna Ben Allal", "Anton Lozhkov", "Elie Bakouch"];
  openHandsAuthors = [
    "Xingyao Wang",
    "Boxuan Li",
    "Yufan Song",
    "Frank F. Xu",
    "Xiangru Tang",
    "Mingchen Zhuge",
    "Jiayi Pan",
    "Yueqi Song",
    "Bowen Li",
    "Jaskirat Singh",
    "Hoang H. Tran",
    "Fuqiang Li",
    "Ren Ma",
    "Mingzhang Zheng",
    "Bill Qian",
    "Yanjun Shao",
    "Niklas Muennighoff",
    "Yizhe Zhang",
    "Binyuan Hui",
    "Junyang Lin",
    "Robert Brennan",
    "Hao Peng",
    "Heng Ji",
    "Graham Neubig"
  ];
  visionAuthors = [
    "Nikhila Ravi",
    "Valentin Gabeur",
    "Yuan-Ting Hu",
    "Ronghang Hu",
    "Chaitanya Ryali",
    "Tengyu Ma",
    "Haitham Khedr",
    "Roman Rädle",
    "Chloe Rolland",
    "Laura Gustafson",
    "Eric Mintun",
    "Junting Pan",
    "Kalyan Vasudev Alwala",
    "Nicolas Carion",
    "Chao-Yuan Wu",
    "Ross Girshick",
    "Piotr Dollár",
    "Christoph Feichtenhofer"
  ];
  postTransformersAuthors = ["Tri Dao", "Albert Gu"];
  openModelsAuthors = [
    "Dirk Groeneveld",
    "Iz Beltagy",
    "Pete Walsh",
    "Akshita Bhagia",
    "Rodney Kinney",
    "Oyvind Tafjord",
    "Ananya Harsh Jha",
    "Hamish Ivison",
    "Ian Magnusson",
    "Yizhong Wang",
    "Shane Arora",
    "David Atkinson",
    "Russell Authur",
    "Khyathi Raghavi Chandu",
    "Arman Cohan",
    "Jennifer Dumas",
    "Yanai Elazar",
    "Yuling Gu",
    "Jack Hessel",
    "Tushar Khot",
    "William Merrill",
    "Jacob Morrison",
    "Niklas Muennighoff",
    "Aakanksha Naik",
    "Crystal Nam",
    "Matthew E. Peters",
    "Valentina Pyatkin",
    "Abhilasha Ravichander",
    "Dustin Schwenk",
    "Saurabh Shah",
    "Will Smith",
    "Emma Strubell",
    "Nishant Subramani",
    "Mitchell Wortsman",
    "Pradeep Dasigi",
    "Nathan Lambert",
    "Kyle Richardson",
    "Luke Zettlemoyer",
    "Jesse Dodge",
    "Kyle Lo",
    "Luca Soldaini",
    "Noah A. Smith",
    "Hannaneh Hajishirzi"
  ];
  testimonials = [
    { text: "Thoth AI brilliantly transformed Best Papers in 2024 announcement into a visually stunning flyer that perfectly balanced academic rigor with engaging design. Their work significantly amplified the visibility of the research in the machine learning community.", author: "Charlotte Xia, Fusion Fund" },
    { text: "All my speakers loved it, the attendees liked it as a guide, it was a conversation item even after the event!", author: "Sywx, Latent Space" },
  ];
  currentTestimonial = 0;
  ButtonSize = ButtonSize;
  ButtonEmphasis = ButtonEmphasis;
  LeftChevronIcon = LeftChevronIcon;
  viewSource(pdfKey: string) {
    const pdfUrl = this.sources[pdfKey];
    window.open(pdfUrl, '_blank');
  }
  nextTestimonial() {
    this.currentTestimonial = (this.currentTestimonial + 1) % this.testimonials.length;
    console.log(this.currentTestimonial);
    console.log(this.testimonials[this.currentTestimonial]);
  }

  prevTestimonial() {
    this.currentTestimonial = (this.currentTestimonial - 1 + this.testimonials.length) % this.testimonials.length;
    console.log(this.currentTestimonial);
    console.log(this.testimonials[this.currentTestimonial]);
  }

  mounted() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault(); // Prevent the default jump behavior

        const targetId = this.getAttribute("href");
        const targetElement = document.querySelector(targetId);

        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      });
    });
  }
}
